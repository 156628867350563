import React, { useEffect, useState } from 'react';
import InputMask from 'react-input-mask';
import TicketCustomize from "../components/ticketCustomize";
import { ageCategories } from '../mock/ageCategories';
import smoothscroll from 'smoothscroll-polyfill';
import { scrollToElem } from '../helpers/index';

import Copy from "../svg/copy.svg"

const TicketsIdentityStep = (props) => {
    const {isDesktop, isMobile, inputValues, setInputValues, handleSubmit, submitting, ticketId, ticketData, setQrOnly} = props;
    
    let [copyText, setCopyText] = useState('Copy link');
    let blocker = false;

    useEffect(() => {
        smoothscroll.polyfill();
        if ('scrollRestoration' in window.history) {
            window.history.scrollRestoration = 'manual';
        }
        //(document.scrollingElement || document.documentElement).scrollTop = 0;
        scrollToElem(0);
    }, []);
    
    function handleChange(e) {
        const {name, value, checked} = e.target;
        const changes = e.target.type === 'checkbox' ? {checked: checked} : {value: value}; 
        
        setInputValues({
            ...inputValues,
            [name]: {
                ...inputValues[name],
                ...changes,
                error: false,
            } 
        });
    }
    
    function handleCopy(e) {
        e.preventDefault();
        if (blocker) return false;
        blocker = true;
        navigator.clipboard.writeText('https://weteleport.com/identity/?t='+ticketId);
        setCopyText('Link has been copied');
        setTimeout(() => { setCopyText('Copy link'); blocker = false}, 3000);
    }

    if (isDesktop) {
        return (
            <div className={`tickets__grid ${ticketData.name?'tickets__grid--centered':''}`}>
                {!ticketData.name && <div className="tickets__grid-section">
                    {!(ticketData.currentPhoto || inputValues.qr_only.value) && <><h2 className="tickets__title _customize">
                        Registration
                    </h2>
                    <div className="tickets__copy">
                        <p className="tickets__hint tickets__hint--large">Send this link to your guest</p>
                        <a href={`https://weteleport.com/identity/?t=${ticketId}`} className="tickets__copy-btn _black btn" role="button" onClick={handleCopy}>
                            {copyText}
                            {copyText === 'Copy link' && <Copy className="tickets__copy-icon" />}
                        </a>
                        <p className="tickets__hint tickets__hint--large">If you are the guest, proceed to choose your pass</p>
                    </div></>}
                    {!!(ticketData.currentPhoto || inputValues.qr_only.value) && <div>
                        <div className="user-form">
                            
                            <p className="user-form__title">ENTER YOUR DETAILS</p>
                            <div className="user-form__input-list">
                                <input className={`user-form__input ${inputValues.name.error ? '_error' : ''}`} type="text" name={inputValues.name.name} placeholder="Full name"
                                    value={inputValues.name.value}
                                    onChange={handleChange}
                                />
                                <InputMask className={`user-form__input ${inputValues.phone.error ? '_error' : ''}`} type="text" name={inputValues.phone.name} placeholder="Phone number"
                                    mask="+999 999999999999"
                                    maskChar=""
                                    value={inputValues.phone.value}
                                    onChange={handleChange}
                                />
                                <input className={`user-form__input ${inputValues.email.error ? '_error' : ''}`} type="text" name={inputValues.email.name} placeholder="Email address"
                                    value={inputValues.email.value}
                                    onChange={handleChange}
                                />
                            </div>
                        </div>
                                       
                        <div className="user-form__info">
                            <p className="user-form__info-summary">Booking Summary: {ticketData.strDate}, {ticketData.time}</p>
                        </div>
                        <div className="tickets__total">
                            <div className="tickets__step-nav">
                                <button className="tickets__next btn" type="button" onClick={handleSubmit}>{submitting ? 'sending...' : 'Submit'}</button>
                            </div>
                            {/* todo */} 
                            
                        </div>
                        <label className={`tickets__conditions form__checkbox ${inputValues.conditions.error ? '_error' : ''}`}>
                            <input type="checkbox" 
                                name={inputValues.conditions.name} 
                                checked={inputValues.conditions.checked}
                                onChange={handleChange}
                            />
                            <span className="form__checkbox-text">
                                <span>I&nbsp;agree with the </span>
                                <a target="_blank" className="link" href="/docs/TC.pdf">terms &amp;&nbsp;conditions</a> and <a target="_blank" className="link" href="/docs/PP.pdf">privacy policy</a>
                            </span>
                        </label>
                    </div>}
                </div>}
                <div className="tickets__grid-section">
                    <TicketCustomize ticketId={ticketId} ticketData={ticketData} qrCode={ticketData.qr} photoMask={ticketData.currentPhoto} handleSubmit={handleSubmit} isDesktop={isDesktop} setQrOnly={setQrOnly} qrOnly={inputValues.qr_only.value}/>
                </div>
            </div>
        )
    } else {
        return (
            <div className="tickets__grid">
                <div className="tickets__grid-section">
                    {!(ticketData.currentPhoto || inputValues.qr_only.value || ticketData.name) && <><h2 className="tickets__title _customize">
                        Registration
                    </h2>
                    <div className="tickets__copy">
                        <p className="tickets__hint tickets__hint--large">Send this link to your guest</p>
                        <a href={`https://weteleport.com/identity/?t=${ticketId}`} className="tickets__copy-btn _black btn" role="button" onClick={handleCopy}>
                            {copyText}
                            {copyText === 'Copy link' && <Copy className="tickets__copy-icon" />}
                        </a>
                        <p className="tickets__hint tickets__hint--large">If you are the guest, proceed to choose your pass</p>
                    </div></>}
                    <TicketCustomize ticketId={ticketId} ticketData={ticketData} qrCode={ticketData.qr} photoMask={ticketData.currentPhoto} handleSubmit={handleSubmit} isDesktop={isDesktop} setQrOnly={setQrOnly} qrOnly={inputValues.qr_only.value}/>
                    {!!(ticketData.currentPhoto || inputValues.qr_only.value) && <div>
                        <div className="user-form">
                            
                            <p className="user-form__title">ENTER YOUR DETAILS</p>
                            <div className="user-form__input-list">
                                <input className={`user-form__input ${inputValues.name.error ? '_error' : ''}`} type="text" name={inputValues.name.name} placeholder="Full name"
                                    value={inputValues.name.value}
                                    onChange={handleChange}
                                />
                                <InputMask className={`user-form__input ${inputValues.phone.error ? '_error' : ''}`} type="text" name={inputValues.phone.name} placeholder="Phone number"
                                    mask="+999 9999-9999"
                                    value={inputValues.phone.value}
                                    onChange={handleChange}
                                />
                                <input className={`user-form__input ${inputValues.email.error ? '_error' : ''}`} type="text" name={inputValues.email.name} placeholder="Email address"
                                    value={inputValues.email.value}
                                    onChange={handleChange}
                                />
                            </div>
                        </div>
                                       
                        <div className="user-form__info">
                            <p className="user-form__info-summary">Booking Summary: {ticketData.strDate}, {ticketData.time}</p>
                        </div>
                        <div className="tickets__total">
                            <div className="tickets__step-nav">
                                <button className="tickets__next btn" type="button" onClick={handleSubmit}>{submitting ? 'sending...' : 'Submit'}</button>
                            </div>
                            {/* todo */} 
                            
                        </div>
                        <label className={`tickets__conditions form__checkbox ${inputValues.conditions.error ? '_error' : ''}`}>
                            <input type="checkbox" 
                                name={inputValues.conditions.name} 
                                checked={inputValues.conditions.checked}
                                onChange={handleChange}
                            />
                            <span className="form__checkbox-text">
                                <span>I&nbsp;agree with the </span>
                                <a target="_blank" className="link" href="/docs/TC.pdf">terms &amp;&nbsp;conditions</a> and <a target="_blank" className="link" href="/docs/PP.pdf">privacy policy</a>
                            </span>
                        </label>
                    </div>}
                </div>
                
            </div>
        )
    }
    
}

export default TicketsIdentityStep