import * as React from "react";

const BuySteps = ({currentStep, setIsPopupOpen, isShort}) => {
    const stepCount = 3;
    let stepArr = new Array(stepCount).fill(null);
    stepArr = stepArr.map((_, i) => i+1);

    const isActive = (step) => step === currentStep;

    return (
    <div className="buy-steps">
        {!isShort && 
            <ul className="buy-steps__list">
                {stepArr.map((step) => (
                    <li 
                        className={`buy-steps__item ${ isActive(step) ? '_active' : ''}`}
                        key={step}
                    >
                        {isActive(step) ? `step ${step}` : step}
                    </li>
                ))}
            </ul>
        }
        {isShort &&
            <ul className="buy-steps__list">
                <li className="buy-steps__item">1</li>
                <li className="buy-steps__item">2</li>
                <li 
                    className="buy-steps__item _active"
                >
                    step 3
                </li>
            </ul>
        }
        {/* <button type="button" className="buy-steps__info-link link"
                    onClick={() => setIsPopupOpen(true)}
                >
                    Everything you need to know
        </button>*/}

    </div>
    )
}

export default BuySteps