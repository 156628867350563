import React, { useEffect, useState } from 'react';
import { navigate } from 'gatsby';

import Mystery1 from '../svg/mystery-1.svg';
import Mystery2 from '../svg/mystery-2.svg';
import Mystery3 from '../svg/mystery-3.svg';
import Mystery4 from '../svg/mystery-4.svg';
import Mystery5 from '../svg/mystery-5.svg';
import Mystery6 from '../svg/mystery-6.svg';
import TeleportLogo from "../svg/teleport_logo.svg";
import { IMG_BASE, FRONT_DOMAIN } from '../config.js';

import Flip from "../svg/flip.svg";
export default () => {
  useEffect(() => {
    navigate('/');
  }, []);
  return null;
};
/*
const Ticket = ({type, date, time, name, qr, photoMask, share_code, endorsement_link, qr_only}) => {
    // todo facebook meta
    
    const [view, setView] = useState(type=='Children ticket'||qr_only?'qr':'photo');
    
    const photo = photoMask ? IMG_BASE + '/' + photoMask : '/static/img/customize.jpg';

    useEffect(() => {
        document.body.classList.add('_without-layout');
    }, []);
    
    const toggleView = () => {
        if (type == 'Children ticket') return false;
        
        setView(view == 'qr' ? 'photo' : 'qr');
    }
    
    const getImg = () => {
        return (view === 'qr'?IMG_BASE + '/' + qr:photo);
    }
    
    const getTicketQRUrl = () => {
        let folder = endorsement_link.substr(0,3);
        return FRONT_DOMAIN + '/rd/tickets_qr/' + folder + '/' + endorsement_link + '.jpg';
    }
    
    const getTicketFaceUrl = () => {
        let folder = endorsement_link.substr(0,3);
        return FRONT_DOMAIN + '/rd/tickets/' + folder + '/' + endorsement_link + '.jpg';
    }
    
    const getShareUrl = () => {
        let url = encodeURIComponent(FRONT_DOMAIN + '/share/?u=' + share_code);
        return 'https://www.facebook.com/sharer/sharer.php?u='+url;
    }
    
    return (
        <div className="identity__container">
            <div className={`identity _${view}`}>
                <div className="identity__card">
                    <div className="identity__content">
                        <h2 className="identity__name">{type == 'Children ticket' ? 'CHILD' : name}</h2>

                        <div className="identity__img-wrap">
                            <img className="identity__img" src={getImg()} alt=""/> 
                            <div className="identity__img-bottom">
                                <div className="identity__icons">
                                    <Mystery1 />
                                    <Mystery2 />
                                    <Mystery3 />
                                </div>
                                {view === 'photo' && <span>visitor</span>}
                                <div className="identity__icons">
                                    <Mystery4 />
                                    <Mystery5 />
                                    <Mystery6 />
                                </div>
                            </div>
                        </div>
                        {/*<div className="identity__share">
                            <p className="identity__share-text">Share the Badge with your Invitee to&nbsp;complete registration</p>
                            <a className="identity__share-btn" href="https://share.com">
                                <span className="identity__btn-content">share ticket</span>
                            </a>
                        </div>*//*}
                        <div className="identity__info">
                            <p className="identity__info-address">11/F, THE L.PLACE,<br/> 139 QUEEN’S ROAD CENTRAL</p>
                            <p className="identity__info-date">d: {date}<br/> T: {time}</p>
                        </div>
                    </div>
                    <div className="identity__bottom">
                        <p className="identity__bottom-text">A&nbsp;new world at&nbsp;the intersection of&nbsp;art, science &amp;&nbsp;technology</p>
                        <TeleportLogo className="identity__logo"/>
                    </div>
                </div>
            </div>
            {type !== 'Children ticket' && !qr_only && <div className="identity__flip" onClick={toggleView}>
                <div className="identity__flip-row">
                    <Flip/>
                    Flip The {view === 'photo'?'Badge':'Ticket'}
                </div>
            </div>}
            
            <div className="identity__share-new">
                {view === 'photo' && <a className="btn _black identity__btn" href={getTicketFaceUrl()} download="badge.jpg">Download the badge</a>}
                {view !== 'photo' && <a className="btn _black identity__btn" href={getTicketQRUrl()} download="ticket.jpg">Download the ticket</a>}
                <a className="btn _black identity__btn" href={getShareUrl()} target="_popup">share on facebook</a>
            </div>
        </div>
    )
}

export default Ticket*/