import React, { useState } from 'react';
import { Link } from 'gatsby';
import { IMG_BASE, ZAPPAR } from '../config.js';

const TicketCustomize = ({qrCode, photoMask, ticketId, ticketData, handleSubmit, qrOnly, isDesktop, setQrOnly}) => {
    const [qrMode, toggleQrCode] = useState();
    
    const getImg = () => {
        const photo = photoMask ? IMG_BASE + '/' + photoMask : '/static/img/customize.jpg';
        return (qrMode||qrOnly?IMG_BASE + '/' + qrCode:photo);
    }
    
    const hasChosen = () => {
        return qrOnly||photoMask;
    }
    
    const redirectToZappar = () => {
        document.location = `${ZAPPAR}?id=${ticketId}`;
        /* const res = handleSubmit();
        if (!res) return false; */
    }
    
    const resetQrOnly = () => {
        setQrOnly(0);
        //redirectToZappar();
    }
    
    const useQrOnly = () => {
        setQrOnly(1);
        if (ticketData.name) {
            const res = handleSubmit();
        }
    }
    
    return (
        <div className="customize">
            <h3 className="customize__title customize__title--larger">{!hasChosen()?'CHOOSE ':''}YOUR PASS</h3>
            {!qrOnly && <div className="customize__top">
                <span className="customize__btn btn _black" onClick={redirectToZappar}>{!photoMask ? 'Custom badge' : 'Change photo'}</span>
                <span className="customize__between-text">or</span>
                <span className="customize__btn btn _black" onClick={useQrOnly}>QR code</span>
            </div>}
            <img className="customize__img" src={getImg()} alt="" />
        </div>
    )
}

export default TicketCustomize