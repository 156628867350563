import { API_BASE } from '../config.js';

const fetch = require("node-fetch");

async function getData({query, name}) {
    try {
        const res = await fetch(`${API_BASE}/${query}`, {
            headers: {
                "Authorization": "Bearer JaKFlA-Jce2WP57DO5GPrb3Vee0s76Vu447luewbbh0Iz8DfYtAB2_BR-5RD3O3IkJAVlEGn-gCnvJzKi28FfA==",
            }
        });
        const data = await res.json();
        
        return {[name]: data};
    } catch(e) {
        console.error(e.message);
    }
}

export {getData};