import React, {useEffect, useState} from 'react';
import { Helmet } from "react-helmet";
import { withPrefix } from 'gatsby';

import "../scss/main.scss";

import BuySteps from "../components/buySteps";
import InfoPopup from "../components/infoPopup";
import TicketsIdentityStep from "../components/TicketsIdentityStep";
import Ticket from "../components/Ticket";
// mock
import { defaultTicketsCount, step2DefaultValues } from "../mock/index";
import { ageCategories } from '../mock/ageCategories';
import { ticketData } from '../mock/ticketData';
import { sendTicketData } from '../api/index';
import { getDateKey } from '../helpers';

import { getData } from '../helpers/fetch';



const TicketsIdentityPage = ( {pageContext: {contacts, modalContent}, location }) => {
    const [isPopupOpen, setIsPopupOpen] = useState(false);
    const [isDesktop, setIsDesktop] = useState();
    const [isNote, setIsNote] = useState();
    const [isMobile, setIsMobile] = useState();
    const [currentStep, setCurrentStep] = useState(2);
    const ticketId = (new URLSearchParams(location.search)).get('t');
    
    const [ticketData, setTicketData] = useState({});
    const [inputValues, setInputValues] = useState(step2DefaultValues);
    const [wasLoaded, setWasLoaded] = useState(false);
    
    useEffect(() => {
       getTicketData();
    }, []);
    
    const getTicketData = () => {
        getData({query: `orders/${ticketId}`, name: 'ticketData'}).then((data) => {
            data.ticketData.name = data.ticketData.user_name;
            
            if (data.ticketData.phone === null || data.ticketData.phone === '') {
                data.ticketData.phone = '852';  
            }
            
            Object.keys(step2DefaultValues).map((k,i)=>step2DefaultValues[k].value = data.ticketData[k] ?? '');
            if (data.ticketData.name) step2DefaultValues.conditions.checked = true;
            
            
            setInputValues({...step2DefaultValues});
            setTicketData(data.ticketData);
            setIsEdit(!checkIsFilled(data.ticketData));
            setWasLoaded(true);
       });
    }
    
    const checkIsFilled = (tData) => {
        return tData && ((tData.name && tData.phone && tData.email && (tData.currentPhoto || tData.photo_mask || tData.qr_only==1)) || tData.type == 'Children ticket' || parseInt(tData.status) == 2);
    }
    
    const [isEdit, setIsEdit] = useState(!checkIsFilled(ticketData));
    
    

    // TODO - submitting - дисаблить кнопки пока форма отправляется и все такое. Нужны состояния в макете.
    const [submitting, setSubmitting] = useState(false);

    

    useEffect(() => {
        checkBreakpoints();
        
        window.addEventListener('resize', checkBreakpoints);
        return () => window.removeEventListener('resize', checkBreakpoints);
    }, []);
    
    useEffect(() => {
        isPopupOpen ? 
            document.body.classList.add('_popup-open') :
            document.body.classList.remove('_popup-open');
    }, [isPopupOpen]);

    // methods
    const checkBreakpoints = () => {
        setIsDesktop(window.innerWidth > 1024);
        setIsMobile(window.innerWidth < 768);
        setIsNote(window.innerWidth > 1024 && window.innerWidth < 1440);
    }

    const isFormCorrect = () => {
        const regexp = {
            name: /.+/, // хотя бы один символ
            phone: /\+\d{3} \d{4,}/, // +852 9999-9999
            email: /.+@.+\..+/, // t@t.t где t - хотя бы один символ
        };
    
        const errors = [];
        Object.entries(inputValues).forEach(([name, data]) => {
            if(data.type === 'text' && data.name!=='qr_only' && !regexp[name].test(data.value)) {
                addError(name);
            } else if(data.type === 'checkbox' && !data.checked) {
                addError(name)
            }
        });
    
        function addError(name) {
            errors.push(name);
        }

        const updatedFields = errors.reduce((acc, name) => {
            return  {               
                ...acc,
                [name]: {
                    ...acc[name],
                    error: errors.some(i => i === name),
                }
            }
        }, inputValues);

        setInputValues({
            ...updatedFields,
        });

        return !errors.length
    }

    const handleSubmit = () => {
        if(!isFormCorrect()) return false;

        const {name, phone, email, conditions, qr_only} = inputValues;

        const data = {
            id: ticketId,
            name: name.value, 
            phone: phone.value,
            email: email.value,
            qr_only: qr_only.value
        }

        return sendTicketData(data, setSubmitting).then((result) => {
            getTicketData();
        });
    }
    
    const setQrOnly = (val) => {
        inputValues.qr_only.value = val;
        setInputValues({...inputValues});
    }
    
    if (!wasLoaded) return null;
   
    if (isEdit) {
        return (
            <>
                <Helmet>
                    <title>Tickets | Teleport</title>
                    <script src={withPrefix('inobounce.js')}></script>
                </Helmet>

                <div className="tickets content-wrap">
                    <div className="tickets__container">
                        <div className="tickets__steps">
                            <BuySteps setIsPopupOpen={setIsPopupOpen} currentStep={currentStep} isShort={true}/>
                        </div>
                            <TicketsIdentityStep 
                                inputValues={inputValues}
                                handleSubmit={handleSubmit}
                                submitting={submitting}
                                
                                setInputValues={setInputValues}
     
                                isDesktop={isDesktop} 
                                isMobile={isMobile} 
                                
                                ticketId={ticketId}
                                ticketData={ticketData}
                                
                                setQrOnly={setQrOnly}
                            />
                    </div>
                </div>

                {isPopupOpen && 
                    <InfoPopup 
                        setIsPopupOpen={setIsPopupOpen} 
                        socialList={contacts.items}
                        modalContent={modalContent.items[0]}
                    />
                }
            </>
        );
    
    } else {
        
        return (
            <>
                <Helmet>
                    <title>Tickets | Teleport</title>
                    <script src={withPrefix('inobounce.js')}></script>
                </Helmet>

                <Ticket name={ticketData.user_name} date={ticketData.date} time={ticketData.time} 
                    qr={ticketData.qr} photoMask={ticketData.currentPhoto} type={ticketData.type} 
                    share_code={ticketData.share_code} endorsement_link={ticketData.endorsement_link}
                    qr_only={ticketData.qr_only}/>
            </>
        );
        
    }
}

export default TicketsIdentityPage;
